@import '../../../../variables.css';

.taskCard {
  padding: 11px 16px;
  border-radius: 15px;
  border: 1px solid var(--g4-color);
  background: var(--white-color);
  margin-bottom: 12px;
}

.upperSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.infoContainer {
  display: flex;
}

.link {
  color: var(--blue-color);
  font-size: 10px;
  font-weight: 500;
  margin-top: 10px;
}

.avatar {
  margin-right: 11px;
}

.date {
  color: var(--g3-color);
  font-size: 10px;
  font-weight: 500;
}

.studentName {
  color: var(--black-color);
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 0;
}

.type {
  color: var(--g2-color);
  font-size: 10px;
  font-weight: 500;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 2px;
}
