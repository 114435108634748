.header .ant-space .ant-space-item .ant-select .ant-select-selector {
    border: none !important;
    font-size: 12px !important;
    padding-left: 0px !important;
    color: #999999 !important;
    margin-right: 0px !important;
  }

@media (width <= 991px) {
  .header .ant-space .ant-space-item .ant-select .ant-select-selector {
    margin-left: 0 !important;
    padding-left: 11px !important;
  }
}
