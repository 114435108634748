@import '../../../variables.css';

.responsiveAttendanceCard {
  padding: 12px 18px;
  border-radius: 15px;
  width: 286px;
  margin-inline: auto;
}

.attendanceUpperSection {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image {
  width: 111.52px;
  height: 59.991px;
  transform: rotate(-7.129deg);
}

.onlineUpperSection {
  display: flex;
  justify-content: space-between;
}

.online {
  border: 1px solid var(--blue-color);
  background: var(--blue-color);
}

.attendance {
  border: 1px solid var(--g4-color);
  background: var(--white-color);
}

.sessions {
  color: var(--white-color);
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.trackName {
  color: var(--p4-color);
  font-size: 12px;
  font-weight: 600;
}

.time {
  color: var(--p4-color);
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

.pendingSessions {
  color: var(--black-color);
  font-size: 16px;
  line-height: 20px;
}

.unreportedSessions {
  color: var(--g2-color);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.linkWrapper {
  display: flex;
}

.link {
  color: var(--blue-color);
  font-size: 10px;
  font-weight: 500;
  display: flex;
  margin-right: 8px;
}

.onlineLink {
  color: var(--white-color);
  font-size: 10px;
  font-weight: 500;
  margin-right: 8px;
}

.notReportedToast {
  border-radius: 200px;
  background: var(--red-color-darker);
  height: 16px;
  padding: 3px 8px;
}

.notReportedToast span {
  color: var(--white-color);
  font-size: 10px;
}

.now {
  border-radius: 200px;
  background: var(--s2-color);
  color: var(--blue-color);
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 4px;
}

.lowerSection {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.notOnlineIcon {
  width: 8px;
  height: 10px;
}

.icon {
  width: 8px;
  height: 10px;
}

.icon path {
  fill: var(--white-color);
}
