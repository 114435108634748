@import '../../../variables.css';

.title {
  color: var(--black-color);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}

.description {
  color: var(--g2-color);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 47px;
  padding: 0 78px;
}

.btn {
  width: 70%;
  margin: auto !important;
  height: 50px;
  margin-bottom: 40px !important;
}

.btn:hover {
  color: var(--white-color) !important;
}

.imageContainer {
  background: var(--green-color);
  border-radius: 50%;
  position: relative;
  width: 89px;
  height: 89px;
  margin: 54px auto;
}

.image {
  width: 91px;
  height: 91px;
}
