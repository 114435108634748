@import '../../../../variables.css';

.studentCard {
    border-radius: 15px;
    border: 1px solid var(--g4-color);
    background: var(--white-color);
    display: flex;
    padding: 15px 18px;
    margin-bottom: 14px;
}

.studentCard:hover {
    border-radius: 15px;
    border: 1px solid var(--blue-color);
    background: var(--s2-color);
}

.link {
    display: none;
    color: var(--blue-color);
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.studentCard:hover .link {
    display: initial;
}

.studentCard:hover .percentage {
    display: none;
}

.avatar {
    margin-right: 10px;
}

.body {
    width: 100%;
}

.upperSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.studentName {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
}

.percentage {
    color: var(--g2-color);
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
}

.lowerSection {
    display: flex;
    justify-content: space-between;
}

.superSkillName {
    color: var(--g2-color);
    font-size: 10px;
    font-weight: 500;
}

.progressWrapper {
    width: 61px;
}
