@import '../../variables.css';

.dashboard {
  padding: 22px 89px;
}

@media (max-width: 576px) {
  .dashboard {
    padding: 0 17px;
  }
}

.slackCard {
  border-radius: 10px;
  border: 1px solid var(--dark-purple);
  background-color: #ECDEEC;
  padding: 3px 46px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.welcome {
  color: var(--colors-main-colors-p-3, var(--black-color));
  font-size: 14px;
  font-weight: 400;
}

.firstName {
  color: var(--colors-main-colors-p-3, var(--black-color));
  font-size: 14px;
  font-weight: 600;
}

.location {
  color: var(--g2-color);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.rightSide {
  display: flex;
  align-items: center;
  gap: 12px;
}

.texts {
  margin-left: 17px;
}

.joinSlack {
  color: var(--black-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: -5px;
}

.slackDescription {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.btnWrapper {
  all: unset;
  padding: 16px 15px;
  border-radius: 13px;
  border: 1px solid var(--g5-color);
  background: var(--white-color);
  cursor: pointer;
  width: 85%;
}

.joinSlackButton {
  width: 136px;
  height: 38px;
  border-radius: 8px;
  color: #FFF;
  background-color: var(--dark-purple);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.joinSlackButton:hover {
  color: var(--white-color) !important;
  background-color: var(--dark-purple) !important;
  border: none !important;
} 

.slackCardReposnive {
  border-radius: 10px;
  border: 1px solid var(--dark-purple);
  background-color: #ECDEEC;
  padding: 3px 26px;
  margin-bottom: 12px;
}

.slackImageResponsive {
  margin-left: 3px;
}

.joinSlackButtonResponsive {
  width: 100%;
  height: 38px;
  border-radius: 8px;
  color: var(--white-color);
  background-color: var(--dark-purple);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 10px;
}

.joinSlackButtonResponsive {
  color: var(--white-color) !important;
}

.joinSlackResponsive {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: -5px;
}

.slackDescriptionResponsive {
  color: var(--black-color);
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
}

.textsResponsive {
  margin-left: 3px;
}

.rightSideResponsive {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
}
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.06);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.06);
}