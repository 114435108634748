@import '../../../variables.css';

.NPSCard {
    border-radius: 15px;
    border: 1px solid var(--g4-color);
    background: var(--white-color);
    padding: 31px 26px;
}

.title {
    color: var(--black-color);
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
}

.selectInput {
    width: 155px !important;
}

.body {
    display: flex;
    justify-content: space-between;
}

.stat {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
}

.percentage {
    margin-right: 11px;
    width: 36px;
    color: var(--black-color);
    font-size: 16px;
    font-weight: 700;
}

.type {
    color: #A6A6A6;
    font-size: 14px;
    font-weight: 500;
}

.noFeedback {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 41px;
    margin-bottom: 68px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: var(--g2-color);
    flex-direction: column;
}

.noFeedback p {
    margin-top: 23px;
}

.footer {
    display: flex;
    justify-content: baseline;
    width: 100px;
    margin-top: 26px;
}

.infoIcon {
    margin-right: 5px;
}

.moreInfo {
    color: #A6A6A6;
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
}