.trackCard {
  width: 100%;
  min-height: 350px;
  height: 100%;
  background-color: var(--white-color);
  border-radius: 10px;
  border: 1px solid var(--g5-color);
  padding: 16px;
  box-sizing: border-box;
}

.box {
  width: 100%;
  height: 262px;
  border-radius: 18px;
  border: 1px solid var(--g4-color);
  overflow: hidden;
}

.cardImg {
  width: 100%;
  height: 262px;
  object-fit: fill;
}


.title {
  padding: 16px 9px;
  padding-bottom: 0;
  color: var(--black-color);
  font-weight: 600;
  font-size: 16px;
  line-height: 19p 0.36px;
  margin: 0;
}

.line {
  width: 100%;
  height: 1px;
  margin-top: 24px !important;
  background-color: var(--g5-color);
}

.card_footer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 52px;

}

.link {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 !important;
  margin-top: 16px !important;
}

.link a {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  color: var(--blue-color);
  margin: 0 !important;
  cursor: pointer;
}


@media (max-width: 992px) {
  .trackCard {
    height: auto;
    min-height: auto;
  }
}