@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import './variables.css';

body {
  min-height: 100vh;
}

.instructorGlobal {
  font-family: "Inter", sans-serif !important;
  position: relative;
  background: var(--body-bg-color);
  :global(.ant-progress-inner){
    background-color:var(--g4-color);
  }
  :global(.ant-select-arrow){
    color: var(--g2-color);
  }
  :global(.ant-pagination-item-link span){
    color: var(--g2-color);
  }
  :global(.ant-picker-suffix){
    color: var(--g2-color);
  }
}

@media (min-width: 992px) {
  .instructorNavbar {
    padding: 17px 30px 10px 30px;
    z-index: 1000 !important;
  }
}

.instructorNavbar p {
  font-family: "Inter", sans-serif !important;
  font-size: 14px;

  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-right: 5px;
}

.instructorNavbar {
  font-family: "Inter", sans-serif !important;
  :global(.ant-switch-handle) {
    display: none;
  }
  :global(.ant-switch-inner-checked svg){
    display: none;
  }
  :global(.ant-switch-inner-checked){
    margin-top: 22px;
  }
  :global(.ant-switch-inner-unchecked){
    margin-top: 22px;
  }
  :global(.ant-switch.ant-switch-checked){
    background-color: #676767;
  }
}
/* .instructorNavbar a {
  height: 35px !important;
} */
.ResponsiveNavbar_labelsWithIconAndDesription__nMSXF {
  border-top: none !important;
}

.darkModeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 90px;
}

.loadingGif {
  position: absolute;
  left: 50%;
  top: 50%;
  /* transform: translate(-50%,-50%); */
}


.darkModeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 90px;
}

.switch {
  display: none;
  background-color: var(--g3-color);
}

.container {
  background-color: var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-left: -24px;
  position: absolute;
  margin-top: 20px;
}

.checked {
  width: 20px;
  height: 30px;
}

.switchDarkMode {
  :global(.ant-switch-inner-checked) {
    display: none !important;
  }

  :global(.ant-switch.ant-switch-checked) {
    background: #87B4FF;
  }
}

.secondContainer {
  background-color: #176FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-left: -1px;
  position: absolute;
}

.sunIcon {
  width: 20px;
  height: 20px;
  margin-left: 1px;
}
