@import '../../../variables.css';

.responsiveTasksCard {
  padding: 12px 20px;
  border-radius: 15px;
  border: 1px solid var(--g4-color);
  background: var(--white-color);
  width: 286px;
  margin: auto;
  max-height: 280px;
  overflow-y: scroll;
}

.header {
  display: flex;
}

.title {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 600;
  margin-right: 20px;
}

.total {
  color: var(--blue-color);
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 200px;
  background: var(--s2-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
