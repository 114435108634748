@import '../../../variables.css';

.onlineSessionCard {
  border-radius: 15px;
  padding: 20px 25px 0px 25px;
  display: flex;
  justify-content: space-between;
}

.current {
  border: 1px solid var(--blue-color);
  background: var(--s2-color);
}

.past {
  border: 1px solid var(--g4-color);
  background: var(--white-color);
}

.trackName {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 5px;
}

.description {
  color: var(--g2-color);
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0px;
  margin-bottom: 23px;
}

.link {
  all: unset;
  color: var(--blue-color);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.rightIcon {
  height: 10px;
}

.sessionsContainer {
  display: flex;
}

.sessions {
  color: var(--blue-color);
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-right: 23px;
}

.toast {
  background: var(--blue-color) !important;
  border-radius: 20px;
}

.toast span {
  color: var(--white-color);
}

.image {
  transform: rotate(-7.129deg);
}

.notReportedToast {
  border-radius: 200px;
  background: var(--red-color-darker);
}

.notReportedToast span {
  color: var(--white-color);
}
