@import '../../../variables.css';

.averagePresenceCard {
    padding: 16px 15px;
    border-radius: 13px;
    border: 1px solid var(--g5-color);
    background: var(--white-color);
    cursor: pointer;
}

.icon {
    margin-bottom: 20px;
}

.icon path {
    fill: var(--g2-color);
}

.amount {
    color: var(--black-color);
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 8px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.title {
    margin: 0px;
    color: var(--g2-color);
    font-size: 12px;
    font-weight: 500;
}