@import '../../../variables.css';

.guildCard {
    padding: 17px 16px;
    border-radius: 13px;
    border: 1px solid var(--g5-color);
    background: var(--white-color);
}

.header {
    display: flex;
    margin-bottom: 13px;
}

.image {
    width: 60px;
    height: 60px;
    margin-right: 9px;
    border-radius: 10px;
}

.session {
    margin: 0px;
    color: var(--blue-color);
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 12px;
}

.guildName {
    color: var(--black-color);
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 3px;
}

.trackName {
    color: var(--g2-color);
    font-size: 10px;
    font-weight: 500;
    margin: 0px;
}

.progressTitle {
    color: var(--g2-color);
    font-size: 10px;
    font-weight: 500;
}

.metrics {
    display: flex;
    justify-content: space-between;
    margin-bottom: -10px;
}

.skillsCount {
    color: var(--g2-color);
    font-size: 10px;
    font-weight: 600;
}

.percentage {
    color: var(--black-color);
    font-size: 10px;
    font-weight: 600;
}

.avatarGroup {
    margin-top: -12px;
    margin-bottom: -8px;
}