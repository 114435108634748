@import '../../../variables.css';

.modal {
    :global(.ant-modal-content) {
        padding: 28px 47px;
        height: 245px;
        border-radius: 26px;
        @media (max-width: 992px) {
            height: 300px;
        }
    }
    :global(.anticon svg) {
        color: var(--g2-color);
        font-size: 14px;
        font-weight: 900;
        line-height: 18px;
        @media (max-width: 992px) {
            font-size: 10px;
        }
    }
    :global(.ant-modal-close) {
        top: 38px !important;
        right: 31px !important;
        width: 26px !important;
        height: 26px !important;
        border-radius: 36px;
        border: 1px solid var(--g2-color);
        @media (max-width: 992px) {
            width: 20px !important;
            height: 20px !important;
        }
    }
}

.title {
    font-size: 22px;
    font-weight: 500;
    line-height: 20px;
    color: var(--g2-color);
}

.footerCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 67px;
}

.label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: var(--black-color)
}

.data {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: var(--black-color)
}

@media (max-width: 992px) {
    .footerCard {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 52px;
    }
    .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: var(--g2-color);
    }
    .label {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: var(--black-color)
    }
    
    .data {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: var(--black-color)
    }
}