@import '../../../variables.css';

.primaryInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.criteriaInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.score {
  width: 58px;
  height: 50px;
  text-align: center;
}

.reason {
  height: 50px;
}

.requirementContainer {
  display: flex;
  width: 100%;
}

.required {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 11px;
  color: var(--red-color-darker);
  margin-top: 7px;
}

.filled {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 11px;
  color: var(--green-color);
  margin-top: 7px;
}

.title {
  color: var(--black-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
