@import '../../../variables.css';

.icon {
    margin-bottom: 20px;
}

.amount {
    color: var(--black-color);
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 8px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.title {
    margin: 0px;
    color: var(--g2-color);
    font-size: 12px;
    font-weight: 500;
}