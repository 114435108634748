@import '../../../variables.css';

.circularProgressBarWithAvatar {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.score {
    display: flex;
}

.currentScore {
    font-size: 24px;
    font-weight: 600;
    margin: 0px;
}

.red {
    color: #F14C4C;
}

.yellow {
   color: #F2D21F; 
}

.green {
    color: var(--green-color);
}

.totalScore {
    color: var(--g2-color);
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
    margin-top: 5px;
}