@import '../../../variables.css';

.tasksCard {
    border-radius: 15px;
    border: 1px solid var(--g4-color);
    background: var(--white-color);
    padding: 27px 25px;
    height: 438px;
    overflow-y: scroll;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
}

.title {
    margin: 0px;
    color: var(--black-color);
    font-size: 16px;
    font-weight: 600;
}

.total {
    border-radius: 200px;
    background: var(--s2-color);
    color: var(--blue-color);
    font-size: 8px;
    font-weight: 700;
    letter-spacing: 1px;
}