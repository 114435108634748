@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import './variables.css';

body {
  font-family: 'Inter', sans-serif !important;
  position: relative;
  background: var(--p4-color);
  margin: 0;
}

html {
  font-family: 'Inter', sans-serif !important;
  position: relative;
  background: var(--p4-color);
}

body.dark {
  --body-bg-color: #1d1d1d;
  --red-color-1: #32201d;
  --red-color-darker: #932a2a;
  --green-color: #37a542;
  --green-color-1: #233328;
  --orange-color: #fc8f2b;
  --orange-color-1: #302a19;
  --black-color: #cacaca;
  --blue-color: #87b4ff;
  --s2-color: #23282d;
  --g4-color: #404040;
  --white-color: #212121;
  --g2-color: #9aa7b4;
  --g5-color: #3c3c3c;
  --g6-color: #252525;
  --g3-color: #676767;
  --p4-color: #1e1e1e;
}

body {
  background: var(--body-bg-color);
}

.ant-menu {
  background: var(--white-color);
}
/* .ant-btn-default {
    background: var(--white-color);

  } */
.ant-select-selector {
  background: var(--white-color) !important;
  color: var(--black-color);
}
.ant-select-selection-placeholder {
  color: var(--g2-color) !important;

}
.ant-drawer-content {
  background: var(--white-color) !important;
}
.ant-timeline-item-head {
  background-color: var(--white-color) !important;
}
.ant-modal-content {
  background-color: var(--white-color) !important;
}

.ant-tabs {
  color: var(--black-color);
}

.ant-tabs-tab-btn {
  color: var(--black-color);
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--blue-color) !important;
}

.ant-input::placeholder {
  color: var(--g2-color) !important;
}
.ant-picker-input::placeholder {
  color: var(--g2-color) !important;
}

.ant-input {
  color: var(--black-color) !important;
  background-color: var(--white-color) !important;
}

.ant-input-outlined {
  color: var(--black-color) !important;
  background: var(--white-color);
}
.ant-input-outlined:focus-within {
  color: var(--black-color) !important;
  background: var(--white-color);
}
.ant-input-outlined:hover {
  color: var(--black-color) !important;
  background: var(--white-color);
  border-color: var(--g4-color);
}
.ant-input-disabled {
  color: var(--black-color) !important;
}
.ant-picker-outlined {
  color: var(--black-color) !important;
  background: var(--white-color);
}

.ant-select-selection-item {
  color: var(--black-color) !important;
}

.PhoneInputInput {
  color: var(--black-color) !important;
}

.ant-select-dropdown, .ant-picker ,.ant-picker-input{
  color: var(--black-color) !important;
  background: var(--white-color);
}

.ant-select-item {
  color: var(--black-color) !important;
}
.ant-btn-primary:disabled {
  color: var(--g2-color);
}

.ant-table ,table {
  background: var(--white-color) !important;
}
th , .ant-table-cell{
  background: var(--white-color) !important;
color: var(--black-color) !important;
}
.ant-input-affix-wrapper {
  color: var(--g2-color);
  background-color: var(--white-color) !important; ;
}
.ant-picker-cell-in-view,.ant-picker-cell-end ,.ant-picker-cell-start {
  color: var(--black-color) !important;

}
.ant-dropdown-menu {
  background-color: var(--white-color) !important; 
}
.ant-btn-default:disabled {
  background-color: var(--g5-color) !important;
  color: var(--g2-color) !important;


}
.ant-picker .ant-picker-input >input {
  color: var(--black-color) !important;

}
.ant-picker .ant-picker-input >input::placeholder {
  color: var(--g2-color) !important;
}
.ant-menu-submenu>.ant-menu {
  background-color: var(--white-color) !important; 

}
.ant-input-number-input-wrap {
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;

}
.ant-input-number-input {
  color: var(--black-color) !important;

}
.ant-input-number {
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;
}
.ant-input-number-input::placeholder {
  color: var(--black-color) !important;

}
.ant-collapse   .ant-collapse-content>.ant-collapse-content-box{
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;
}
.ant-empty-description {
  color: var(--black-color) !important;

}
.ant-pagination-item a {
  color: var(--black-color) !important;

}
.ant-select  {
  color: var(--black-color) !important;

}
.ant-select::placeholder  {
  color: var(--black-color) !important;

}
.MultiSelectSearch .title {
  color: var(--black-color) !important;

}
.react-tel-input .form-control {
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;

}