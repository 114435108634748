@import '../../variables.css';

.labPhaseContainer {
  padding: 21px 50px;
}

.labPhaseFinalApproval {
  border-radius: 20px;
  border: 1px solid var(--g4-color);
  background: var(--white-color);
  padding: 42px 70px;
}

.title {
  color: var(--black-color);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.description {
  color: var(--g2-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid var(--g4-color);
  padding-bottom: 29px;
}

.subDescription {
  color: var(--blue-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  margin-top: 34px;
  margin-bottom: 34px;
}

.QuestionContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.requirementContainer {
  display: flex;
  width: 100%;
}

.required {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 11px;
  color: var(--red-color-darker);
  margin-top: 7px;
}

.filled {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 11px;
  color: var(--green-color);
  margin-top: 7px;
}

.textArea {
  width: 100%;
  min-height: 102px;
  border-radius: 10px;
  border: 1px solid var(--g4-color);
  background: var(--white-color);
  padding: 16px 0 0 16px;
}

.approvedFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feedbackBtn {
  width: 215px;
  height: 50px;
}

.feedbackBtn:hover {
  color: var(--white-color) !important;
}

.backBtn {
  color: var(--g2-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-bottom: 0;
}

.backBtn svg path {
  fill: var(--g2-color);
}

.personalizedTitle {
  color: var(--g2-color);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 36px;
}

a {
  text-decoration: none;
}
