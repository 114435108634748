@import '../../../variables.css';

.navigationMenu {
  border-right: 1px solid var(--g4-color);
  padding-top: 85px;
  height: 100vh;
  position: relative;
  max-width: 60%;
}

.active {
  color: var(--black-color);
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 29px;
  cursor: pointer;
}

.item {
  color: var(--g2-color);
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 29px;
  cursor: pointer;
}

.itemIcon {
  width: 18px;
  height: 12px;
}

.itemIcon path {
  fill: var(--g2-color);
}

.reportIssue {
  border-top: 1px solid var(--blue-color);
  padding-top: 20px;
  position: absolute;
  bottom: 175px;
  color: var(--blue-color);
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 29px;
  cursor: pointer;
  width: 100%;
}

.curriculumItem {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
  margin-left: 22%;
  height: 27px;
  background: var(--blue-color);
  align-items: center;
  padding-right: 6px;
  cursor: pointer;
}

.curriculumItemOpen {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
  margin-left: 22%;
  height: 27px;
  background: var(--s2-color);
  align-items: center;
  padding-right: 6px;
  cursor: pointer;
}