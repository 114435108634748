@import '../../../variables.css';

.responsiveTrackCard {
  padding: 13px;
  border-radius: 15px;
  border: 1px solid var(--g4-color);
  background: var(--white-color);
  display: flex;
  width: 286px;
  margin: auto;
}

.image {
  width: 84px;
  height: 81px;
  margin-right: 11px;
  border-radius: 10px;
}

.trackName {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 600;
  margin-top: 0;
}

.metrics {
  color: #999;
  font-size: 10px;
  font-weight: 500;
}

.link {
  color: var(--blue-color);
  font-size: 10px;
  font-weight: 500;
}

.icon {
  margin-left: 8px;
}
