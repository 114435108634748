@import "../../../variables.css";

.studentsListCard {
  border-radius: 15px;
  border: 1px solid var(--g4-color);
  background: var(--white-color);
  padding: 27px 25px;
  height: 534px;
  overflow-y: scroll;
}

.title {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 37px;
}
.loadMoreButton {
  width: 100%;
  height: 50px;
}
