:root {
    --body-bg-color: var(--p4-color);
    --red-color-1: var(--Colors-Functional-Colors-F01, #FCEFED);
    --red-color-darker: var(--Colors-Functional-Colors-F1, #E55353);
    --green-color: var(--Colors-Functional-Colors-F2, #4CCD59);
    --green-color-1: var(--Colors-Functional-Colors-F02, #EDF9F1);
    --orange-color: var(--Colors-Functional-Colors-F3, #FDA04B);
    --orange-color-1: var(--Colors-Functional-Colors-F03, #FDEED4);
    --black-color: var(--Colors-Main-Colors-P3, #333);
    --blue-color: var(--Colors-Main-Colors-P1, #176FFF);
    --s2-color: var(--Colors-Secondary-Colors-S2, #F3F8FF);
    --g4-color: var(--Colors-Gray-G4, #EBECF0);
    --white-color: var(--Colors-Main-Colors-P2, #FFF); 
    --g2-color: var(--Colors-Gray-G2, #828A92);
    --g5-color: var(--Colors-Gray-G5, #F2F2F2);
    --g6-color: var(--Colors-Gray-G6, #FAFAFA);
    --g3-color: var(--Colors-Gray-G3, #B9BDC1);
    --p4-color: var(--Colors-Main-Colors-P4, #FCFCFC);
    --purple: #8E59FF;
    --dark-purple: #611F69;
    --light-purple: #EEF1FF;
    --light-pink: #FFF0F5;
    --dark-pink: #FF80A9;
    --light-purple-1: #ECE3FF;
    --light-blue: #DEF7FF;
    --light-blue-1: #01A7DB;
}
  
[data-theme='dark'] {
    --body-bg-color: #1D1D1D;
    --red-color-1: #32201D;
    --red-color-darker: #932A2A;
    --green-color: #37A542;
    --green-color-1: #233328;
    --orange-color: #FC8F2B;
    --orange-color-1: #302A19;
    --black-color: #CACACA;
    --blue-color: #87B4FF;
    --s2-color: #23282D;
    --g4-color: #404040;
    --white-color: #212121;
    --g2-color: #9AA7B4;
    --g5-color: #3C3C3C;
    --g6-color: #252525;
    --g3-color: #676767;
    --p4-color: #1E1E1E;
    --purple: #ECE3FF;
    --dark-purple: #C474D3;
    --light-purple: #5168D9;
    --light-pink: #FF80A9;
    --dark-pink: #FFF0F5;
    --light-purple-1: #8E59FF;
    --light-blue: #01A7DB;
    --light-blue-1: #DEF7FF;
}

  