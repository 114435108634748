@import '../../../../variables.css';

.taskCard {
    border-radius: 15px;
    border: 1px solid var(--g4-color);
    background: var(--white-color);
    padding: 21px 22px;
    margin-bottom: 14px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.studentName {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
}

.date {
    color: var(--g3-color);
    font-size: 10px;
    font-weight: 500;
    margin: 0px;
}

.body {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.type {
    color: var(--g2-color);
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
}

.link {
    color: var(--blue-color);
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
    cursor: pointer;
}