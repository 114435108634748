@import '../../../variables.css';

.keep {
  width: 50px;
  position: absolute;
  top: 35px;
  right: 35px;
}

.responsiveNPSCard {
  border-radius: 15px;
  border: 1px solid var(--green-color);
  background: var(--green-color-1);
  width: 286px;
  margin: auto;
  padding: 12px 17px;
  display: flex;
  justify-content: space-between;
}

.infos {
  width: 180px;
}

.title {
  color: var(--g2-color);
  font-size: 10px;
  font-weight: 600;
  margin: 0px;
}

.subTitle {
  color: var(--g2-color);
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.percentage {
  color: var(--black-color);
  font-size: 9px;
  font-weight: 700;
}

.type {
  color: #a6a6a6;
  font-size: 9px;
  font-weight: 500;
}

.score {
  display: flex;
  align-items: baseline;
}

.currentScore {
  color: var(--green-color);
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
}

.totalScore {
  color: var(--g2-color);
  font-size: 9px;
  font-weight: 600;
  margin: 0px;
}
