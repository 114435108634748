@import '../../../variables.css';

.graduatedStudentsCard {
    padding: 16px 15px;
    border-radius: 13px;
    border: 1px solid var(--g5-color);
    background: var(--white-color);
}

.icon {
    margin-bottom: 20px;
}

.amount {
    color: var(--black-color);
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 8px;
}

.title {
    color: var(--g2-color);
    font-size: 12px;
    font-weight: 500;
}