.title {
  color: var(--g2-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.description {
  color: var(--black-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 43px;
}
.container {
  border-radius: 15px;
  border: 1px solid var(--g4-color);
  background: var(--white-color);
  padding: 45px 65px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blockedCard {
  padding: 22px 90px;
}
.subTitle {
  color: var(--black-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
}

.subDescription {
  color: var(--black-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 11px;
}
.button {
  height: 50px;
  width: 257px;
  margin-top: 45px;
}
.button:hover {
  background: var(--blue-color) !important;
  color: var(--white-color) !important;
}
@media (max-width: 992px) {
  .blockedCard {
    padding: 16px;
  }
  .title {
    font-size: 10px;
  }
  .description {
    font-size: 12px;
  }
  .subTitle {
    font-size: 12px;
  }
  .subDescription {
    font-size: 10px;
  }
  .container {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 28px;
  }
  .container img {
    width: 77.48px;
    height: 99.034px;
  }
}
